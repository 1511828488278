// styles
import "./ScheduleModal.scss";


import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';

// sources
import xIcon from "../../img/x.webp";

import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';

import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import axios from "axios";

import SteinStore from "stein-js-client";



const horasDeshabilitadas = [
  '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM'
];

// const horasHabilitadas = [
//   '10:00 AM',
//   '07:00 PM',
// ];

const generateSchedule = (horasHabilitadas) => {
  const opcionesHoras = horasDeshabilitadas.map((hora) => ({
    label: hora,
    value: hora,
    disabled: !horasHabilitadas.includes(hora)
  }));

  return opcionesHoras
}


const DropdownHoras = ({value, setValue, options}) => {
  return (
    <Dropdown
      options={options}
      placeholder="Selecciona una hora"
      appendTo={"self"}
      value={value}
      onChange={e => setValue(e.value)}
      autoFocus={true}
      id="hourselector"
    />
  );
}













function ScheduleCalendar({
  setSelectedDate,
  selectedDate,
  handleSelectNewDate 
}) {
  const [selectedDateRecord, setSelectedDateRecord] = useState({})
    
  
  locale('es');
  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    today: 'Hoy',
    clear: 'Limpiar',
  });

  function getWeekOfMonth(date) {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOfWeek = (firstDayOfMonth.getDay() + 6) % 7; // El día de la semana empieza en domingo (0) y queremos que empiece en lunes (1)
    const dayOfMonth = date.getDate() - (firstDayOfMonth.getDay() - 1);
    return Math.floor((dayOfMonth - 1) / 7) + 1;
  }
  
  function getDatesExceptMondaysAndFridays() {
    const today = new Date();
    const endOfYear = new Date(today.getFullYear(), 11, 31); // Último día del año
    const dates = [];
  
    while (today <= endOfYear) {
      const weekOfMonth = getWeekOfMonth(today);
      if ((weekOfMonth === 1 || weekOfMonth === 3) && (today.getDay() === 2 || today.getDay() === 4)) {
        // Si es semana 1 o 3 y es martes o jueves, no lo agregamos
      } else if ((weekOfMonth === 2 || weekOfMonth === 4) && (today.getDay() === 1 || today.getDay() === 5)) {
        // Si es semana 2 o 4 y es lunes o viernes, no lo agregamos
      } else {
        dates.push(new Date(today));
      }
      today.setDate(today.getDate() + 1); // Avanzamos al siguiente día
    }
  
    return dates;
  }
  
  useEffect(() => {
    setSelectedDateRecord(selectedDate)

    if(selectedDateRecord.toString() != selectedDate.toString()) {
      handleSelectNewDate()
    }
  }, [selectedDate])

  return (
    <Calendar
      value={selectedDate}
      onChange={(e) => setSelectedDate(e.value)}
      disabledDates={getDatesExceptMondaysAndFridays()}
      minDate={new Date()}
      inline
      selectionMode="single"
      yearRange="2023:2024"
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
    />
  );
}





function obtenerFechaFormateada(date) {
  const diasDeLaSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const mesesDelAnio = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  
  const diaDeLaSemana = diasDeLaSemana[date.getDay()];
  const dia = date.getDate();
  const mes = mesesDelAnio[date.getMonth()];
  const anio = date.getFullYear();
  return `${diaDeLaSemana} ${dia < 10 ? '0' + dia : dia}/${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}/${anio}`;
}



export default function({
  leadData,
  updateLeadData, 
  setLeadData,
  
  setScheduleModal,
  scheduleModal,
}) {
  const [postReady, setPostReady] = useState(false)
  const [ableSelectHour, setAbleSelectHour] = useState(false)
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedHour, setSelectedHour] = useState({});
  const [hourOptions, setHourOptions] = useState([])
  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/6474e358d27cdd09f0f71ebd"
  );

  const prePost = (meeting=false) => {
    if(meeting) {
      updateLeadData("meeting", `${obtenerFechaFormateada(new Date(selectedDate))} - ${selectedHour}`)
      setPostReady("Agendar mi cita")
    } else {
      setPostReady("No, gracias")
    }
  }

  useEffect(() => {
    if(postReady) {
      const loadingSwal = Swal.fire({
        title: 'Enviando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
      
      store
        .append("agenda", [leadData])
        .then(res => {
          loadingSwal.close()
          Swal.fire({
            title: 'Registro enviado con éxito',
            icon: 'success',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#0F70D4",
            timer: 3000
          }).then(() => {
            if(postReady == "Agendar mi cita") {
              window.location.href = `https://api.whatsapp.com/send?phone=5213315344074&text=Hola%20buen%20d%C3%ADa%2C%20mi%20nombre%20es%20${encodeURIComponent(leadData.name)}%20tengo%20${encodeURIComponent(leadData.age)}%20a%C3%B1os%20y%20agend%C3%A9%20mi%20cita%20asesor%C3%ADa%20financiera%20este%20${encodeURIComponent(leadData.meeting)}`;
            }
          })
        })
        .catch(error => {
          console.error(error)
        });
    }
  }, [postReady])

  const handleSelectNewDate = () => {
    setAbleSelectHour(true)
    setSelectedHour({})
    
    const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const diaSeleccionado = diasSemana[new Date(selectedDate).getDay()]
    // GENERO LAS OPCIONES HABILES DEPENDIENDO EL DÍA
    
    switch(diaSeleccionado) {
      case "Lunes": setHourOptions(generateSchedule(["07:00 PM"])); break;
      case "Martes": setHourOptions(generateSchedule(["10:00 AM"])); break;
      case "Jueves": setHourOptions(generateSchedule(["07:00 PM"])); break;
      case "Viernes": setHourOptions(generateSchedule(["10:00 AM"])); break;
    }
  }
  
  useEffect(() => {
    if(ableSelectHour) {
      setTimeout(() => document.getElementById("hourselector").click(), 10)
    }
  }, [ableSelectHour])
    
  return(
  // <div className="ScheduleModalBG">
  //   <div className="ScheduleModal">
  <>
      {/* title */}
      <div>
        <h3 className="h3">¡Tenemos las siguientes fechas y horarios disponibles!</h3>
      </div>

      
      <div style={{height: "20px"}} />
            
      

      {/* schedule comp */}
      <div className="SelectorArea">
        <ScheduleCalendar 
          handleSelectNewDate={handleSelectNewDate} 
          
          selectedDate={selectedDate} 
          setSelectedDate={setSelectedDate} 
        />

        <div className="hourPicker">
          {
            ableSelectHour ? <DropdownHoras options={hourOptions} value={selectedHour} setValue={setSelectedHour} />

            : <h4 className="h4">Selecciona una fecha</h4>
          }
        </div>
      </div>
      


      <div style={{height: "50px"}} />



      {/* actions */}
      <div className="sActions">
        <a href="#" onClick={() => prePost(true)} className={`cta --small ${!Object.keys(selectedHour).length && "disabled"}`}>Agendar mi cita</a>
      </div>
    {/* </div>
  </div> */}

  </>
  )
}