import ScheduleModal from "../../Components/ScheduleModal/ScheduleModal"
import Swal from 'sweetalert2';

// styles
import "./CTAScreen.scss"

// components
import FormTemplate from "../../Components/FormTemplate/FormTemplate"
import { useEffect, useState } from "react"

// data
const asesores = ["Luis Lujano", "Daniel Rodriguez", "Mauricio Carrillo", "Pablo Aguirre", "Alfonso Gutierrez"]
const offices = [
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7471.652789683993!2d-103.42102232717615!3d20.55428289089477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f533ab2fae2ab%3A0x1c95eb7cd92e5376!2sResidencial%20San%20Pablo%2C%20Jalisco!5e0!3m2!1sen!2smx!4v1680319059698!5m2!1sen!2smx",
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.4969551889403!2d-103.3787314855696!3d20.69003510463223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428afcda29c56df%3A0xc28fdbc74269c0d3!2sC.%20Jos%C3%A9%20Mar%C3%ADa%20Vigil%202370%2C%20Italia%20Providencia%2C%2044648%20Guadalajara%2C%20Jal.!5e0!3m2!1sen!2smx!4v1680318857384!5m2!1sen!2smx",
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3594.824182761457!2d-100.30895668547755!3d25.710244617004427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866295a7d9814647%3A0x403d15313dbd05f4!2sAsesores%20Clave!5e0!3m2!1sen!2smx!4v1680319152008!5m2!1sen!2smx",
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.546427951987!2d-99.16943768558906!3d19.4319939457365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8caadb3f42d%3A0x7daf8836571398f0!2sC.%20R%C3%ADo%20Sena%2041%2C%20Cuauht%C3%A9moc%2C%2006500%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1sen!2smx!4v1680319186524!5m2!1sen!2smx",
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d238879.83993793128!2d-103.376472!3d20.690013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af734e1d9797%3A0x69363fce83dd172c!2sIntercenter%20Lopez%20Mateos%20Oficinas%20Virtuales!5e0!3m2!1sen!2sus!4v1680319225425!5m2!1sen!2sus"
]

export default function({
  updateLeadData, leadData, setLeadData
}) {
  const [asesor, setAsesor] = useState("")
  const [office, setOffice] = useState("")
  const [scheduleModal, setScheduleModal] = useState(0)

  function popRandom(arr) { // GENERATED BY CHAT GPT-3
    // Generar un índice aleatorio
    const index = Math.floor(Math.random() * arr.length);
  
    // Eliminar el elemento en ese índice
    const elementoEliminado = arr.splice(index, 1)[0];
  
    // Devolver el arreglo actualizado y el elemento eliminado
    return elementoEliminado;
  }

  const pickRandomData = () => {
    setAsesor(popRandom(asesores))
    setOffice(popRandom(offices))
  }
  
  useEffect(() => {
    Swal.fire({
      title: '¡Hola!',
      text: 'Por ahora tenemos los siguientes días de la semana y horarios disponibles',
      icon: 'info',
      timer: 3500,
      timerProgressBar: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCloseButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    })
  }, [])

  useEffect(() => pickRandomData(), [])

  return (<FormTemplate className={`CTAScreen`}>
    <ScheduleModal updateLeadData={updateLeadData} leadData={leadData} scheduleModal={scheduleModal} setScheduleModal={setScheduleModal} />
  </FormTemplate>)
}