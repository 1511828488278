import { useEffect, useState } from "react";

// src
import logo from "../src/img/logo.png"

// components
// import Step1 from "./Pages/Steps/Step1 copy/Step1";
import Step3 from "./Pages/Steps/Step3/Step3";
import Step6 from "./Pages/Steps/Step6/Step6";
import StepBtn from "./Components/StepBtn/StepBtn";
import CTAScreen from "./Pages/CTAScreen/CTAScreen";

// styles
import "./Styles/Globals.scss"
import "./Styles/Steps.scss"
import "./Styles/Layout.scss"

function App() {
  const [leadData, setLeadData] = useState({
    name: "",
    age: "",
    meeting: "",
  })
  const [stepCounter, setStepCounter] = useState(0)
  const [ableNextStep, setAbleNextStep] = useState(true)

  const updateLeadData = (property, value, p2, v2) => {
    const copy = {...leadData};
    copy[property] = value;
    
    if(p2 && v2) {
      copy[p2] = v2;
    }

    setLeadData(copy)
  }

  // props packs
  const StepsProps = {stepCounter, setStepCounter, ableNextStep, setAbleNextStep}
  const LeadData = {updateLeadData, leadData, setLeadData}
  
  // GET RECORDS
  // useEffect(() => {
  //   fetch('https://asesores-api.vercel.app/api/records/get')
  //     .then(response => response.json())
  //     .then(data => console.log(data))
  //     .catch(error => console.error(error));
  // }, [])
  

  return (<div className="layout">
    <div className="bg-overlay"/>
    
    <header className="Header"><img onClick={()=> window.location.href = "https://www.asesoresclave.com"} src={logo}/></header>
    
      <main className="App">
        <StepBtn disabled={true} {...StepsProps} direction="left" />

        {stepCounter == 0 && <Step3 {...StepsProps} {...LeadData}/>}
        {stepCounter > 0 && <CTAScreen {...LeadData} />} 
        
        <StepBtn disabled={!ableNextStep || stepCounter > 0} {...StepsProps} direction="right" />

        <div className="mobile-actions">
          <StepBtn disabled={true} {...StepsProps} direction="left" />
          <StepBtn disabled={!ableNextStep || stepCounter > 0} {...StepsProps} direction="right" />
        </div>
      </main>
  </div>);
}

export default App;
